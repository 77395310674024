<template>
  <div class="vx-row">
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="removeProductGroup"
      :active.sync="askRemoveProductGroup"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> Do you want to remove this Product from Basket? </span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="removeBasket"
      :active.sync="askRemoveBasket"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> Do you want to remove this Product from Basket? </span>
      </div>
    </vs-prompt>
    <div class="vx-col w-full">
      <div class="vx-row w-full productGroupRow m-0">
        <!-- Image -->
        <div class="vx-col sm:w-1/12"></div>
        <!-- Product Title -->
        <div
          class="vx-col sm:w-3/12 flex justify-center"
          style="cursor: pointer"
          @click="SortWithTitle"
        >
          Product Title
          <vs-icon
            size="small"
            v-if="titleSortDirection != null"
            :icon="
              titleSortDirection == 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'
            "
          ></vs-icon>
        </div>
        <!-- Quantity -->
        <div class="vx-col sm:w-2/12 flex justify-center">Quantity</div>
        <!-- Gold Weight -->
        <div class="vx-col sm:w-1/12 flex justify-center">
          {{ selectedWeightMultipier.weightType }}
        </div>
        <!-- Resin/Credit -->
        <div class="vx-col sm:w-1/12 flex justify-center">Resin/Credit</div>
        <!-- Clear All -->
        <div class="vx-col sm:w-3/12 flex justify-center">
          <vs-button color="dark" @click="askRemoveBasket = true">
            Clear All
          </vs-button>
        </div>
        <!-- Expand -->
        <div class="vx-col sm:w-1/12"></div>
      </div>

      <div class="mt-4" v-for="group in getAddedProductGroups" :key="group + 5">
        <div
          class="vx-row w-full productGroupRow mb-1 m-0"
          :style="
            CheckSuitabilitySize(group) ? '' : 'background-color : #EA5455'
          "
        >
          <div class="vx-col sm:w-1/12">
            <vs-avatar
              class="productGroupImage"
              size="large"
              :src="getProductGroup(group).image"
            />
          </div>
          <div class="vx-col sm:w-3/12 flex justify-center">
            {{ getProductGroup(group).title }}
          </div>
          <div class="vx-col sm:w-2/12"></div>
          <div class="vx-col sm:w-1/12 flex justify-center">
            {{
              (
                getProductGroupWeight(group) * selectedWeightMultipier.value
              ).toFixed(2)
            }}
          </div>
          <div class="vx-col sm:w-1/12 flex justify-center">
            {{
              (getProductGroupWeight(group) * weightMultipier.resin).toFixed(2)
            }}
          </div>
          <div class="vx-col sm:w-3/12 flex justify-center">
            <vs-button
              :disabled="orderConfirm"
              radius
              :color="CheckSuitabilitySize(group) ? '#FFFFFF' : '#EA5455'"
              @click.native="
                askRemoveProductGroup = true;
                removingProductGroupId = group;
              "
            >
              <vs-icon icon="delete" color="dark" size="small"></vs-icon>
            </vs-button>
          </div>
          <div class="vx-col sm:w-1/12 flex justify-center">
            <vs-icon
              style="cursor: pointer"
              v-if="expandedGroups.includes(group)"
              icon="remove"
              color="light"
              bg="primary"
              size="medium"
              round
              @click="expandedGroups.splice(expandedGroups.indexOf(group), 1)"
            ></vs-icon>
            <vs-icon
              style="cursor: pointer"
              v-else
              icon="add"
              color="light"
              bg="primary"
              size="medium"
              round
              @click="expandedGroups.push(group)"
            ></vs-icon>
          </div>
        </div>

        <div
          v-for="(item, index) in getProductWithProductGroup(group)"
          :key="item.id"
        >
          <basketProductRow
            :item="item"
            :index="index"
            :orderConfirm="orderConfirm"
            v-if="expandedGroups.includes(group)"
            @CheckBasket="CheckBasket"
          />
        </div>
      </div>
    </div>
    <div class="vx-col w-full mb-4 mt-4" style="text-align: -webkit-center">
      <orderSummary @orderConfirm="ChangeOrderConfirm" />
    </div>
  </div>
</template>

<script>
import basketProductRow from "./components/BasketProductRow.vue";
import orderSummary from "./components/OrderSummary.vue";

export default {
  name: "Basket",
  data() {
    return {
      titleSortDirection: null,
      expandedGroups: [],
      askRemoveProductGroup: false,
      removingProductGroupId: null,
      askRemoveBasket: false,
      orderConfirm: false,
    };
  },
  methods: {
    CheckSuitabilitySize(id) {
      for (
        let index = 0;
        index < this.$store.getters["noktaMarket/basket"].length;
        index++
      ) {
        const element = this.$store.getters["noktaMarket/basket"][index];
        if (
          element.product_group.id == id &&
          element.sizeSuitability == false
        ) {
          return false;
        }
      }
      return true;
    },
    ChangeOrderConfirm(val) {
      this.orderConfirm = val;
    },
    CheckBasket() {
      if (this.basketItems.length === 0) {
        this.$router.push("/nokta_market").catch(() => {});
      }
    },
    CompareTitle(a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    },
    SortWithTitle() {
      if (this.titleSortDirection === null) {
        this.titleSortDirection = "asc";
      } else if (this.titleSortDirection === "asc") {
        this.titleSortDirection = "desc";
      } else {
        this.titleSortDirection = "asc";
      }
    },
    removeBasket() {
      this.$store.dispatch(
        "noktaMarket/clearBasket",
        this.removingProductGroupId
      );
      this.$vs.notify({
        color: "success",
        title: "Success",
        text: "Your basket has been cleared!",
      });
      this.$router.push("/nokta_market").catch(() => {});
    },
    removeProductGroup() {
      this.$store.dispatch(
        "noktaMarket/removeProductGroup",
        this.removingProductGroupId
      );

      this.$vs.notify({
        color: "success",
        title: "Success",
        text: "Product removed!",
      });

      this.CheckBasket();
    },
    getProductGroup(id) {
      return this.getBasketProductGroups.filter((e) => e.id == id)[0];
    },
    getProductGroupWeight(id) {
      let total = 0.0;
      this.basketItems.forEach((e) => {
        if (e.product_group.id == id) {
          total += parseFloat(e.volume) * e.quantity;
        }
      });
      return total;
    },
    getProductWithProductGroup(id) {
      return this.basketItems.filter((e) => e.product_group.id == id);
    },
  },

  computed: {
    weightMultipier() {
      return this.$store.getters["noktaMarket/weightMultipier"];
    },
    selectedWeightMultipier() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
    basketItems() {
      let tmpList = [];
      if (this.titleSortDirection != null) {
        tmpList = [...this.$store.getters["noktaMarket/basket"]];
        if (this.titleSortDirection === "asc") {
          tmpList.sort(this.CompareTitle);
        } else {
          tmpList.sort(this.CompareTitle).reverse();
        }
      } else {
        return this.$store.getters["noktaMarket/basket"];
      }
      return tmpList;
    },
    getBasketProductGroups() {
      return this.$store.getters["noktaMarket/productGroups"];
    },
    getAddedProductGroups() {
      let tmp = [];
      if (this.basketItems.length > 0) {
        this.basketItems.forEach((item) => {
          let index = tmp.findIndex((i) => i == item.product_group.id);
          if (index == -1) {
            tmp.push(item.product_group.id);
          }
        });
      }

      return tmp;
    },
  },
  components: {
    basketProductRow,
    orderSummary,
  },
  mounted() {
    if (this.basketItems.length == 0) {
      this.$router.push("/nokta_market").catch(() => {});
    }
  },
  destroyed() {
    this.$store.dispatch("noktaMarket/ClearItemSizeSuitability");
  },
};
</script>

<style scoped>
.productGroupRow {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  font-weight: 600;
  color: #000;
}
.productGroupImage {
  border: solid 1px #c9c9c9;
}
</style>

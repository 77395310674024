var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-prompt',{attrs:{"accept-text":_vm.$t('Accept'),"cancel-text":_vm.$t('Cancel'),"title":_vm.$t('Control_Product'),"active":_vm.askRemoveItem,"color":"dark"},on:{"accept":_vm.removeItem,"update:active":function($event){_vm.askRemoveItem=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_c('span',[_vm._v(" Do you want to remove this item from Basket? ")])])]),_c('vs-prompt',{attrs:{"accept-text":_vm.$t('Accept'),"cancel-text":_vm.$t('Cancel'),"title":_vm.$t('Control_Product'),"active":_vm.askRemoveItemQuantity,"color":"dark"},on:{"accept":_vm.removeItem,"cancel":function($event){_vm.tmpQuantity = 1},"close":function($event){_vm.tmpQuantity = 1},"update:active":function($event){_vm.askRemoveItemQuantity=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_c('span',[_vm._v(" Do you want to remove this item from Basket? ")])])]),_c('div',{staticClass:"vx-row w-full itemRow m-0",style:(_vm.item.sizeSuitability
        ? _vm.index % 2 == 1
          ? 'background-color: #eee;'
          : 'background-color: #fff'
        : 'background-color: #EA5455')},[_c('div',{staticClass:"vx-col md:w-1/12"}),_c('div',{staticClass:"vx-col md:w-3/12 flex justify-center"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('div',{staticClass:"vx-col md:w-2/12 flex justify-center"},[_c('vs-input-number',{staticClass:"ml-5",attrs:{"disabled":_vm.orderConfirm,"max":"999","min":"0","color":"primary"},on:{"input":_vm.changeQuantity},model:{value:(_vm.tmpQuantity),callback:function ($$v) {_vm.tmpQuantity=$$v},expression:"tmpQuantity"}})],1),_c('div',{staticClass:"vx-col md:w-1/12 flex justify-center"},[_vm._v(" "+_vm._s((_vm.item.volume * _vm.selectedWeightMultipier.value).toFixed(2))+" ")]),_c('div',{staticClass:"vx-col md:w-1/12 flex justify-center"},[_vm._v(" "+_vm._s((_vm.item.volume * _vm.weightMultipier.resin).toFixed(2))+" ")]),_c('div',{staticClass:"vx-col md:w-3/12 flex justify-center"},[_c('vs-button',{attrs:{"disabled":_vm.orderConfirm,"radius":"","color":_vm.item.sizeSuitability
            ? _vm.index % 2 == 1
              ? 'background-color: #eee;'
              : 'background-color: #fff'
            : 'background-color: #EA5455'},nativeOn:{"click":function($event){_vm.askRemoveItem = true}}},[_c('vs-icon',{attrs:{"icon":"delete","color":"dark","size":"small"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
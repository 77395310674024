<template>
  <div class="orderSummary p-4" style="max-width: 400px">
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      title="Confirm Order"
      @accept="PlaceOrder"
      :active.sync="askPlaceOrder"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> Do you confirm the order? </span>
      </div>
    </vs-prompt>

    <p class="flex p-0" style="font-weight: 500">Machine Type</p>

    <v-select
      :disabled="getMachineTypes.length == 1 || orderConfirm"
      class="mt-2 mb-4"
      v-model="selectedMachineType"
      :placeholder="$t('Machines')"
      label="name"
      @input="ChangeMachineType"
      :options="getMachineTypes"
    >
    </v-select>

    <vs-alert class="mb-4" color="danger" :active="showMachineTypeError">
      Select Machine Type
    </vs-alert>

    <div class="mb-6" style="font-weight: 600">Order Summary</div>

    <div class="flex justify-between">
      <div>Total Different Product Unit</div>
      <div>{{ basketItems.length }}</div>
    </div>
    <div class="flex justify-between">
      <div>Total Product Unit</div>
      <div>{{ totalProductUnit }}</div>
    </div>
    <div class="flex justify-between">
      <div>Total Credit/Resin Gr</div>
      <div>{{ (totalResinCredit * weightMultipier.resin).toFixed(2) }} gr</div>
    </div>

    <div class="mt-4" style="cursor: pointer" @click="CheckAllOrder">
      <vs-checkbox :disabled="!checkOrder" v-model="orderConfirm">
        Confirm Order
      </vs-checkbox>
    </div>

    <div class="flex justify-center">
      <vs-button
        @click="askPlaceOrder = true"
        :disabled="true"
        class="w-full mt-6 mb-6"
        color="dark"
      >
        PLACE ORDER
      </vs-button>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

export default {
  data() {
    return {
      askPlaceOrder: false,
      selectedMachineType: null,
      orderConfirm: false,
      showMachineTypeError: false,
    };
  },
  methods: {
    ChangeMachineType() {
      this.$store.dispatch("noktaMarket/ClearItemSizeSuitability");
    },
    CheckAllOrder() {
      if (!this.checkOrder) {
        this.showMachineTypeError = true;
      } else {
        this.showMachineTypeError = false;
      }
    },
    PlaceOrder() {
      let order = {};

      let orderItems = [];

      for (let index = 0; index < this.basketItems.length; index++) {
        const element = this.basketItems[index];
        if (element.quantity > 0) {
          orderItems.push({
            product: element.id,
            quantity: element.quantity,
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: element.title,
            text: "Quantity must be greater than 0",
          });
          return;
        }
      }

      order.items = orderItems;
      order.machine_type = this.selectedMachineType.name;
      order.plate_width = this.selectedMachineType.general_width;
      order.plate_height = this.selectedMachineType.general_height;

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.post(
        API.BASEURL + API.NOKTA_MARKET_ORDERS,
        this.HandlePlaceOrder,
        order
      );
    },
    HandlePlaceOrder(status, data) {
      this.$vs.loading.close();

      if (status == 201) {
        if (data.order_status == "N") {
          this.$store.dispatch("decreaseNoktaMarketCredit", data.total_weight);
        }
        this.$vs.notify({
          title: "Success",
          text: "Order placed successfully",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$store.dispatch("noktaMarket/clearBasket");

        let params = {
          id: data.id,
        };
        this.$router.push({
          name: "nokta_order_detail",
          query: params,
        });
      } else {
        if (data.data.items) {
          this.orderConfirm = false;
          this.$vs.notify({
            time: 15000,
            title: "Size Problem",
            text:
              this.GetPlateNames(data.data.items) +
              " Product Size is not compatible with Machine Type",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x",
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Order failed",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x",
          });
        }
      }
    },
    GetPlateNames(items) {
      Object.keys(items).forEach((element) => {
        this.$store.dispatch("noktaMarket/setSizeProblem", element);
      });

      return Object.keys(items).join(",");
    },
  },
  computed: {
    checkOrder() {
      if (this.selectedMachineType == null) {
        return false;
      } else {
        return true;
      }
    },
    getMachineTypes() {
      return this.$store.getters["getMachineTypes"];
    },
    totalProductUnit() {
      return this.$store.getters["noktaMarket/getTotalProductUnit"];
    },
    totalResinCredit() {
      return this.$store.getters["noktaMarket/getTotalResinCredit"];
    },
    weightMultipier() {
      return this.$store.getters["noktaMarket/weightMultipier"];
    },
    basketItems() {
      return this.$store.getters["noktaMarket/basket"];
    },
  },
  watch: {
    orderConfirm(val) {
      this.$emit("orderConfirm", val);
    },
    getMachineTypes(newValue) {
      if (newValue.length == 1) {
        this.selectedMachineType = newValue[0];
      }
    },
  },
  mounted() {
    if (this.getMachineTypes.length == 1) {
      this.selectedMachineType = this.getMachineTypes[0];
    }
  },

  components: {
    vSelect,
  },
};
</script>

<style scoped>
.orderSummary {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  color: #000;
}

.orderSummary .flex {
  padding-bottom: 10px;
}
</style>

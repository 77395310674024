<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="removeItem"
      :active.sync="askRemoveItem"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> Do you want to remove this item from Basket? </span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="removeItem"
      @cancel="tmpQuantity = 1"
      @close="tmpQuantity = 1"
      :active.sync="askRemoveItemQuantity"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> Do you want to remove this item from Basket? </span>
      </div>
    </vs-prompt>
    <div
      class="vx-row w-full itemRow m-0"
      :style="
        item.sizeSuitability
          ? index % 2 == 1
            ? 'background-color: #eee;'
            : 'background-color: #fff'
          : 'background-color: #EA5455'
      "
    >
      <div class="vx-col md:w-1/12"></div>

      <div class="vx-col md:w-3/12 flex justify-center">
        {{ item.title }}
      </div>
      <div class="vx-col md:w-2/12 flex justify-center">
        <vs-input-number
          :disabled="orderConfirm"
          class="ml-5"
          max="999"
          min="0"
          color="primary"
          v-model="tmpQuantity"
          @input="changeQuantity"
        />
      </div>
      <div class="vx-col md:w-1/12 flex justify-center">
        {{ (item.volume * selectedWeightMultipier.value).toFixed(2) }}
      </div>
      <div class="vx-col md:w-1/12 flex justify-center">
        {{ (item.volume * weightMultipier.resin).toFixed(2) }}
      </div>
      <div class="vx-col md:w-3/12 flex justify-center">
        <vs-button
          :disabled="orderConfirm"
          radius
          :color="
            item.sizeSuitability
              ? index % 2 == 1
                ? 'background-color: #eee;'
                : 'background-color: #fff'
              : 'background-color: #EA5455'
          "
          @click.native="askRemoveItem = true"
        >
          <vs-icon icon="delete" color="dark" size="small"></vs-icon>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "index", "orderConfirm"],
  data() {
    return {
      askRemoveItem: false,
      askRemoveItemQuantity: false,
      tmpQuantity: this.item.quantity,
    };
  },
  methods: {
    changeQuantity() {
      if (this.tmpQuantity == "" || this.tmpQuantity < 0) {
        this.tmpQuantity = 1;
      }
      if (this.tmpQuantity == 0 && this.tmpQuantity != "") {
        this.askRemoveItemQuantity = true;
        return;
      }

      if (this.tmpQuantity > 999) {
        this.tmpQuantity = 999;
      }
      if (this.tmpQuantity < 1000 && this.tmpQuantity > 0) {
        this.$store.dispatch("noktaMarket/changeQuantity", {
          id: this.item.id,
          quantity: this.tmpQuantity,
        });
      }
    },
    removeItem() {
      this.$store.dispatch("noktaMarket/removeItem", this.item);
      this.$vs.notify({
        color: "success",
        title: "Success",
        text: this.item.title + " removed!",
      });

      this.$emit("CheckBasket");
    },
  },
  computed: {
    selectedWeightMultipier() {
      return this.$store.getters["noktaMarket/selectedWeightMultipier"];
    },
    weightMultipier() {
      return this.$store.getters["noktaMarket/weightMultipier"];
    },
  },
  watch: {
    tmpQuantity(newValue) {
      if (newValue > 999) {
        this.tmpQuantity = 999;
      }
      if (newValue < 0) {
        this.tmpQuantity = 0;
      }
    },
  },
};
</script>

<style scoped>
.itemRow {
  align-items: center;
  color: #000;
  font-weight: 600;
}
</style>
